body
{
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code
{
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.bgw
{
	background-color: #fff;
}

.logo-box .name
{
/*	color: red;*/
}

.logo
{
	height: 60px;
	margin: -8px 0;
}

.carousel-item img
{
	width: 100%;
}

.imgw100
{
	width: 100%;
}

.navbar-toggler
{
	border: 0!important;
}

.navbar-toggler:focus
{
	box-shadow: none!important;
}

.wrapper
{
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.wrapper .card
{
	height: 100%;
}

.wrapper .card .card-body
{
	display: flex;
	flex-direction: column;
}

.wrapper .card .card-body .spacer
{
	margin-top: auto;
}

.wrapper .card button
{
	width: 100%;
}

.PhoneInputInput
{
	border: 0!important;
}

#floatingInput,
.PhoneInputInput,
#floatingInput:focus,
.PhoneInputInput:focus
{
	outline: none;
	-webkit-appearance: none;
	box-shadow: none;
	border-color: #dee2e6;
}